import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M5060 10494 c-456 -27 -1002 -145 -1448 -314 -237 -89 -556 -237
-710 -329 -29 -17 -55 -31 -58 -31 -8 0 -243 -149 -342 -218 -399 -274 -786
-636 -1098 -1027 -580 -726 -954 -1624 -1068 -2565 -38 -313 -45 -783 -16
-1080 78 -793 339 -1560 757 -2230 74 -117 245 -366 263 -380 3 -3 28 -34 55
-70 27 -36 54 -70 60 -76 5 -6 32 -38 60 -70 153 -181 440 -466 605 -600 19
-16 37 -31 40 -35 5 -6 101 -80 210 -163 188 -142 495 -330 742 -453 998 -498
2118 -658 3203 -458 627 116 1250 358 1790 697 115 72 285 186 295 198 3 3 46
36 95 73 333 252 737 661 986 997 451 609 761 1307 913 2060 101 499 122 1054
61 1587 -106 921 -492 1848 -1069 2568 -337 422 -759 809 -1176 1080 -58 37
-117 76 -132 86 -50 35 -343 195 -443 243 -562 270 -1057 413 -1700 492 -125
15 -714 27 -875 18z m900 -83 c398 -48 711 -119 1075 -245 200 -68 442 -169
605 -251 751 -377 1388 -925 1869 -1605 454 -641 757 -1401 870 -2185 74 -509
67 -1068 -20 -1565 -132 -749 -406 -1418 -839 -2042 -61 -88 -84 -119 -198
-264 -261 -333 -683 -728 -1040 -975 -699 -482 -1474 -776 -2307 -875 -459
-54 -1008 -36 -1465 46 -817 149 -1561 480 -2210 984 -509 396 -952 906 -1273
1468 -60 104 -217 410 -217 422 0 2 -15 37 -34 78 -19 40 -57 132 -84 203
-372 981 -433 2025 -177 3039 117 463 269 836 530 1301 61 108 278 435 333
500 9 11 41 52 71 90 214 275 553 613 836 831 193 149 424 306 572 390 178
100 237 131 365 192 563 272 1152 428 1838 486 25 2 200 2 390 1 269 -3 381
-8 510 -24z"/>
<path d="M2396 8273 c-18 -168 -28 -491 -22 -708 11 -386 40 -674 96 -948 45
-217 74 -321 141 -504 143 -390 320 -667 599 -942 288 -283 660 -513 1230
-761 91 -39 174 -75 185 -80 79 -35 235 -100 239 -100 3 0 29 -11 58 -24 29
-14 100 -45 158 -69 156 -66 222 -96 360 -163 201 -97 351 -176 346 -181 -2
-3 -42 9 -88 27 -197 76 -418 145 -712 220 -87 22 -193 50 -235 62 -42 11 -89
24 -106 28 -249 62 -598 193 -802 301 -239 125 -391 238 -588 434 -154 153
-280 315 -399 513 -161 265 -341 660 -413 907 -16 52 -28 73 -39 62 -8 -7 16
-169 63 -427 109 -601 275 -1052 522 -1415 93 -137 158 -213 302 -351 263
-251 612 -427 1119 -561 126 -33 454 -104 730 -158 41 -8 102 -21 135 -29 208
-48 290 -68 295 -73 6 -6 -138 4 -250 17 -41 5 -140 12 -220 15 -222 9 -521
35 -695 60 -639 92 -1117 385 -1536 940 -68 89 -240 350 -283 428 -24 44 -42
65 -48 59 -17 -17 145 -429 262 -667 109 -221 123 -245 233 -410 90 -135 136
-192 246 -307 156 -163 335 -295 513 -376 334 -154 642 -201 1303 -202 463 -1
801 -22 1020 -66 392 -78 714 -230 1008 -475 54 -45 102 -78 106 -73 16 17
-103 443 -163 589 -7 17 -28 68 -46 115 -113 292 -283 586 -482 837 -93 117
-328 349 -458 453 -239 191 -625 424 -1000 603 -946 452 -1262 645 -1624 997
-373 363 -634 809 -825 1410 -25 81 -43 151 -111 435 -5 22 -19 92 -30 155
-11 63 -24 138 -29 165 -6 28 -12 77 -16 110 -8 85 -27 185 -36 185 -3 0 -9
-26 -13 -57z"/>
<path d="M3300 7733 c-31 -16 -35 -22 -35 -58 0 -51 25 -65 117 -65 103 0 266
-20 331 -41 85 -27 137 -67 173 -133 51 -93 56 -177 63 -985 l6 -735 70 -48
c58 -40 139 -92 215 -138 100 -61 302 -166 750 -390 574 -286 725 -368 948
-517 449 -299 772 -638 1037 -1087 150 -253 258 -509 371 -873 16 -51 36 -95
44 -99 8 -3 56 -13 105 -21 72 -13 204 -17 654 -20 591 -5 640 -2 661 38 31
56 -6 99 -84 99 -173 0 -476 106 -695 243 -130 81 -347 256 -511 411 -117 110
-506 501 -610 611 -47 50 -146 155 -220 234 -74 79 -142 152 -150 161 -8 9
-67 73 -130 141 -63 68 -136 146 -161 174 -25 27 -65 72 -90 99 -24 27 -49 54
-55 60 -13 14 -199 220 -290 322 -201 224 -354 405 -354 420 0 12 1353 1366
1530 1530 299 279 547 434 795 498 89 23 238 46 299 46 61 0 106 27 106 63 0
27 -8 38 -40 60 -30 21 -114 22 -535 6 -237 -10 -386 -9 -707 1 -439 14 -462
12 -474 -35 -8 -33 -1 -67 16 -76 8 -5 40 -18 70 -29 99 -35 117 -114 55 -236
-38 -76 -79 -132 -211 -292 -136 -165 -232 -269 -615 -666 -68 -71 -133 -139
-144 -151 -11 -12 -74 -77 -140 -146 -304 -314 -575 -597 -600 -626 -60 -72
-57 -115 -52 825 3 532 9 893 16 951 27 234 92 305 303 336 57 8 125 16 152
17 40 2 53 8 69 30 25 33 17 69 -19 89 -23 12 -83 13 -358 9 -475 -7 -766 -6
-1231 3 -387 8 -412 8 -445 -10z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
